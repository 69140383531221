import React from 'react'
// import { Link } from 'gatsby'
// import insta from '../../images/icon/instafooter.svg'
import Logo from '../../images/Footer/logo-footer.svg'
import * as styles from './footer.module.scss'
import linkedin from '../../images/Footer/linkedin.svg'
import { injectIntl, FormattedMessage, Link } from 'gatsby-plugin-react-intl'

const Footer = () => {
  const date = new Date()
  const year = date.getFullYear()
  return (
    <>
      <footer className={styles.bgFooter} id="sectioncontact">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 d-lg-flex d-none justify-content-start">
              <img src={Logo} className="w-100 pr-5" alt="Logo Lwart em Branco"/>
            </div>
            <div className="col-lg-5 col-6 d-lg-flex d-none justify-content-lg-end justify-content-start pr-5">
              <div>
                <span>GRUPO LWART</span>
                <div className="d-lg-flex mt-3">
                  <ul className="mb-0 p-0">
                    <li><Link to="/historia/" className={styles.ancoraFooter}><FormattedMessage id="links.historia.name" /></Link></li>
                    <li><Link to="/governanca/" className={styles.ancoraFooter}><FormattedMessage id="links.governanca.name" /></Link></li>
                    <li><Link to="/negocios/" className={styles.ancoraFooter}><FormattedMessage id="links.negocios.name" /></Link></li>
                    {/* <li><Link to="/work-with-us/" className={styles.ancoraFooter}><FormattedMessage id="links.trabalheconosco.name" /></Link></li> */}
                  </ul>
                  <ul className="mb-0 ml-lg-4 pl-auto pl-0">
                    {/* <li><Link to="/privacy-policy/" className={styles.ancoraFooter}><FormattedMessage id="links.politicaprivacidade.name" /></Link></li> */}
                    {/* <li><Link to="/lgpd/" className={styles.ancoraFooter}>LGPD</Link></li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-12 d-flex justify-content-lg-end">
              <div className={styles.customWidth}>
                <span className="d-lg-block jsutify-content-lg-start d-flex justify-content-center"><FormattedMessage id="links.faleconosco.name" /></span>
                <div className="d-flex mt-3 w-100">
                  <ul className="mb-0 p-0 w-100">
                    <li className="d-lg-block d-none"><a className={styles.ancoraFooter}>(14) 3269-5010</a></li>
                    <li className="d-lg-block d-none"><a className={styles.ancoraFooter}>contato@grupolwart.com.br</a></li>
                    <li className="d-lg-none justify-content-lg-start d-flex justify-content-center"><a className={styles.ancoraFooter}>(14) 3269-5010</a></li>
                    <li className="d-lg-none justify-content-lg-start d-flex justify-content-center"><a className={styles.ancoraFooter}>contato@grupolwart.com.br</a></li>
                    <li className="d-lg-block justify-content-lg-start d-flex justify-content-center">
                      <div className="d-flex mt-3">
                        <a href='https://br.linkedin.com/company/grupo-lwart' target="_blank" rel="noopener noreferrer"><img src={linkedin} /></a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className={`container-fluid ${styles.copyright}`}>
        <div className="row h-100 justify-content-center align-items-center">
          <div className="col-auto">
            <p className="text-center">Copyright © {year} <br className="d-lg-none d-block" /><FormattedMessage id="copyright" /></p>
          </div>
        </div>
      </div>
    </>
  )
}

export default injectIntl(Footer)
