// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-governanca-js": () => import("./../../../src/pages/governanca.js" /* webpackChunkName: "component---src-pages-governanca-js" */),
  "component---src-pages-historia-js": () => import("./../../../src/pages/historia.js" /* webpackChunkName: "component---src-pages-historia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-negocios-js": () => import("./../../../src/pages/negocios.js" /* webpackChunkName: "component---src-pages-negocios-js" */)
}

