import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage, Link } from 'gatsby-plugin-react-intl'
import menuSandwich from '../../images/Header/menu-sandwich.svg'
import menuCloseButton from '../../images/Header/menu-close-button.svg'
// import { useStaticQuery, graphql, Link as GatsbyLink } from 'gatsby'
import { Link as ScrollerLink } from 'react-scroll'

// Styles
import * as styles from './header.module.scss'

// Assets
import Logo from '../../images/Header/Logo.svg'
import LogoMobile from '../../images/Footer/logo-footer.svg'

const Desktop = ({ props }) => {
  // const data = useStaticQuery(graphql`{
  //   pagina: allContentfulPagina {
  //     edges {
  //       node {
  //         path
  //         template
  //         node_locale
  //       }
  //     }
  //   }
  // }
  // `)
  // const filterPage = data.pagina.edges.filter(pag => pag.node.template === props.template)
  const { initialDarkBackground } = props
  const headerElement = useRef()
  const [displayDropDown, setDisplayDropDown] = useState(false)
  // const [active, setActive] = useState(false)
  useEffect(() => {
    const refValue = headerElement.current
    if (!initialDarkBackground) {
      window.addEventListener('scroll', () => {
        if (window.pageYOffset > 20) {
          refValue.style.backgroundColor = 'transparent'
        } else {
          refValue.style.backgroundColor = 'transparent'
        }
      })
    }
  })

  // function SectionVitalForce (event) {
  //   event.preventDefault()
  //   scroller.scrollTo('SectionVitalForce', {
  //     smooth: true,
  //     duration: 500,
  //     className: styles.navDropdown,
  //     activeClassName: styles.activePage
  //   })
  // }
  // function SectionLwart (event) {
  //   event.preventDefault()
  //   scroller.scrollTo('SectionLwart', {
  //     smooth: true,
  //     duration: 400,
  //     className: styles.navDropdown,
  //     activeClassName: styles.activePage
  //   })
  // }
  return (
    <Fragment>
      <header
        id={styles.defaultHeader}
        style={
          initialDarkBackground
            ? { backgroundColor: '#282828' }
            : { backgroundColor: 'transparent' }
        }
        ref={headerElement}
      >
        <div className="container d-lg-block d-none">
          <div className="row justify-content-end padding-top-custom">
            <div className={`${styles.language} text-white col-auto`}>
              {/* <span className="mr-0 cursor-pointer" onClick={() => changeLocale('pt')}>PT</span> /
              <span className="ml-1 cursor-pointer" onClick={() => changeLocale('en')}>EN</span> */}
            </div>
          </div>

          <div className="row justify-content-between">
            <div className="col-lg-auto col-10">
              <Link to="/">
                <img src={Logo} alt="icone do grupo lwart" />
              </Link>
            </div>
            <div
              id={styles.navigation}
              className='col d-flex align-items-center justify-content-end'
            >
              <ul id={styles.linksList}>
                <li>
                  <Link
                    to="/historia/"
                    role="navigation"
                    className={styles.navLinks}
                    activeClassName={styles.activePage}
                  >
                    <FormattedMessage id="links.historia.name" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/governanca/"
                    role="navigation"
                    className={styles.navLinks}
                    activeClassName={styles.activePage}
                  >
                    <FormattedMessage id="links.governanca.name" />
                  </Link>
                </li>
                <li className="dropdown"
                  onMouseOver={() => setDisplayDropDown(true)}
                  onMouseLeave={() => setDisplayDropDown(false)}
                >
                  <Link
                    to="/negocios/"
                    role="navigation"
                    className={styles.navLinks}
                    activeClassName={styles.activePage}
                  >
                    <FormattedMessage id="links.negocios.name" />
                  </Link>
                </li>
                <ul
                  className={`dropdown-menu ${props.intl.locale === 'en' ? styles.rightEn : styles.rightPt} ${styles.dropdown}`}
                  style={{
                    display: displayDropDown ? 'inline-block' : 'none'
                  }}
                  onMouseOver={() => setDisplayDropDown(true)}
                  onMouseLeave={() => setDisplayDropDown(!displayDropDown)}
                >
                  <li className={styles.dropDownItem}>
                    <Link to='/negocios/#SectionLwart' className={styles.navDropdown} style={{ textDecoration: 'none' }}>
                      Lwart Soluções Ambientais
                    </Link>
                  </li>
                  <hr className='p-0 m-0' />
                  <li className={styles.dropDownItem}>
                    <Link to='/negocios/#SectionVitalForce' className={styles.navDropdown} style={{ textDecoration: 'none' }}>
                      VitalForce
                    </Link>
                  </li>
                </ul>
                <li>
                  <ScrollerLink to="/negocios/sectioncontact"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={1000}
                    className={styles.navLinks}
                    activeClassName={styles.activePage}>
                    <FormattedMessage id="links.faleconosco.name" />
                  </ScrollerLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </Fragment>
  )
}

const Mobile = () => {
  const [openMenu, setOpenMenu] = useState(false)
  const handleMenuMobile = () => {
    setOpenMenu(!openMenu)
    if (!openMenu) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
      document.getElementsByTagName('html')[0].style.overflow = 'hidden'
    } else {
      document.getElementsByTagName('html')[0].style.overflow = 'auto'
      document.getElementsByTagName('body')[0].style.overflow = 'auto'
    }
  }

  return (
    <>
      {openMenu &&
        <nav className={`${styles.heightMenuOpen} position-fixed w-100 ${!openMenu ? styles.hideNavMobile : undefined}`}>
          {/* <div className="row justify-content-start padding-top-custom pt-4 pl-3">
            <div className={`${styles.languageMobile} col-2`}>
              <span className="mr-1 cursor-pointer" onClick={() => {
                changeLocale('pt')
                handleMenuMobile()
              }} >PT</span>
            </div>
            <div className={`${styles.languageMobile} col-2`}>
              <span className="mr-1 cursor-pointer"onClick={() => {
                changeLocale('en')
                handleMenuMobile()
              }} >EN</span>
            </div>
          </div> */}
          <div className="d-flex flex-column align-items-start">
            <ul id={styles.mobileLink}>
              <li>
                <Link
                  to="/historia/"
                  role="navigation"
                  className={styles.navLinks}
                  activeClassName={styles.activePage}
                  onClick={() => handleMenuMobile()}
                >
                  <p><FormattedMessage id="links.historia.name" /></p>
                </Link>
              </li>
              <li>
                <Link
                  to="/governanca/"
                  role="navigation"
                  className={styles.navLinks}
                  activeClassName={styles.activePage}
                  onClick={() => handleMenuMobile()}
                >
                  <p><FormattedMessage id="links.governanca.name" /></p>
                </Link>
              </li>
              <li>
                <Link
                  to="/negocios/"
                  role="navigation"
                  className={styles.navLinks}
                  activeClassName={styles.activePage}
                  onClick={() => handleMenuMobile()}
                >
                  <p><FormattedMessage id="links.negocios.name" /></p>
                </Link>
              </li>
              <li className='ml-5'>
                <ScrollerLink to="SectionLwart"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={1000}
                  className={styles.navLinks}
                  activeClassName={styles.activePage}
                  onClick={() => handleMenuMobile()}>
                  <p className='font-size-18'>Lwart Soluções Ambientais</p>
                </ScrollerLink>
                {/* <Link
                  to="#"
                  className={styles.navLinks}
                  activeClassName={styles.activePage}
                  onClick={() => handleMenuMobile()}
                >
                  <p>Lwart Soluções Ambientais</p>
                </Link> */}
              </li>
              <li className='ml-5'>
                <ScrollerLink to="SectionVitalForce"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={1000}
                  className={styles.navLinks}
                  activeClassName={styles.activePage}
                  onClick={() => handleMenuMobile()}>
                  <p className='font-size-18'>VitalForce</p>
                </ScrollerLink>
              </li>
              <li>
                <ScrollerLink to="sectioncontact"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={1000}
                  className={styles.navLinks}
                  activeClassName={styles.activePage}
                  onClick={() => handleMenuMobile()}>
                  <p><FormattedMessage id="links.faleconosco.name" /></p>
                </ScrollerLink>
              </li>
            </ul>
          </div>
        </nav>}
      <nav className={'background-color-blue-dark d-flex align-items-center position-fixed w-100 d-lg-none above'}>
        <div className="container">
          <div className="d-flex justify-content-between align-items-center py-1">
            <div>
              <Link to="/" onClick={() => openMenu === true && handleMenuMobile()}>
                <img src={LogoMobile} alt="icone do grupo lwart" width="150" height="61" />
              </Link>
            </div>
            <div onClick={() => handleMenuMobile()}>
              <img alt={openMenu ? 'x - icone de fechar' : 'sandwich - icone para abrir o menu'} src={openMenu ? menuCloseButton : menuSandwich} width="34" height="34" /> {/* Fix close button svg */}
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}
const Header = (props) => {
  return (
    <Fragment>
      <Desktop props={props} />
      <Mobile props={props} />
    </Fragment>
  )
}

export default injectIntl(Header)

Header.propTypes = {
  initialDarkBackground: PropTypes.bool.isRequired
}
