import React, { Fragment } from 'react'
// components
import Header from '../Header/header'
import Footer from '../../components/Footer'
import 'swiper/css/swiper.css'

// styles
import '../../scss/base.scss'
import '../../scss/plugins/bootstrap.scss'

// fonts
import '../../fonts/Spinnaker/Spinnaker.css'

const Layout = ({ children, location }) => {
  return (
    <Fragment >
      <Header initialDarkBackground={ false } location={location} />
      <main>
        { children }
      </main>
      <Footer />
    </Fragment>
  )
}

export default Layout
